import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../core/search.service';

@Component({
  selector: 'ykn-search-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.less']
})
export class SearchSwitchComponent implements OnInit {

  constructor(public search:SearchService) { }

  ngOnInit() {
  }

}
