import { Component, OnInit, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'ykn-nlist-item',
  templateUrl: './nlist-item.component.html',
  styleUrls: ['./nlist-item.component.less']
})
export class NlistItemComponent implements OnInit {

  constructor(private element:ElementRef,private render:Renderer2) {
    render.setAttribute(element.nativeElement,"draggable","true");
    //this.render.setStyle(this.element.nativeElement,"visibility","visible");
   }

  @Input() url:string;
  @Input() icon:string;
  @Input() name:string;
  @Input() index:number;

  ngOnInit() {

  }

  @HostListener("dragstart",["$event"])
  ondragstart(event:DragEvent){
    event.dataTransfer.setData("Text1",this.name);
    this.render.setStyle(this.element.nativeElement,"opacity","0.5");
  }

  
  @HostListener("dragend",["$event"])
  ondragend(event:DragEvent){
    this.render.setStyle(this.element.nativeElement,"opacity","1");
  }

  @HostListener("drop",["$event"])
  ondrop(event:DragEvent){
    event.preventDefault();
    console.log(event.dataTransfer.getData("Text1"));
    event.dataTransfer.clearData();
  }
  
  @HostListener("dragover",["$event"])
  ondragover(event:DragEvent){

    event.preventDefault();
  }
}
