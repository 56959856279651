import { Component, OnInit, Input,Output, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { SearchResult, HistorySearchResult,ChipResult } from '../../../core/search-result';
import { Subject } from 'rxjs';

/* 展示型组件 */
@Component({
  selector: 'ykn-search-suggesstion-item',
  templateUrl: './item.component.html',
  changeDetection:ChangeDetectionStrategy.OnPush,//此组件从构造开始之后就不会改变了
  styleUrls: ['./item.component.less']
})
export class SearchSuggesstionItemComponent implements OnInit {

  @Input() content:SearchResult;

  @Input() isActive:boolean;

  @Output() onAction:Subject<any>;
  constructor() { 
    this.onAction=new Subject();
  }

  ngOnInit() {
  }

  @HostBinding('class')
  get _class(){
    return `${this.isActive?'active':''}${this.content.description!=undefined?' detailed':' '}${this.content instanceof ChipResult?' chip':''}`;
  }

  get isHistory(){
    return this.content instanceof HistorySearchResult;
  }

  get imgType(){
    if(this.isHistory){
      return '/assets/close.svg'
    }
    else{
      return undefined;
    }
  }

  onClickAction(e:MouseEvent){
    e.preventDefault();
    this.onAction.next(undefined);
  }
}
