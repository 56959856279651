import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID, Inject } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { Routes,RouterModule, RouteReuseStrategy} from '@angular/router';

import { MouseWheelDirective } from './core/mouse-wheel.directive';

import { AppComponent } from './app.component';
import { isPlatformServer } from '@angular/common';
import { SimpleReuseStrategy } from './core/route-reuse';

import { SearchComponent } from './search/search.component';
import { SearchSuggesstionComponent} from './search/suggesstion/suggesstion.component';
import { SearchSwitchComponent} from './search/switch/switch.component';
import { SearchSuggesstionItemComponent} from './search/suggesstion/item/item.component';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SearchPageComponent } from './search-page/search-page.component';
import { PortalComponent } from './portal/portal.component';
import { NavComponent } from './portal/nav/nav.component';
import { FeedComponent } from './portal/feed/feed.component';
import { AutoResizeDirective } from './core/auto-resize.directive';
import { ListComponent } from './search-page/list/list.component';
import { NlistItemComponent } from './search-page/list/nlist-item/nlist-item.component';
const appRoutes:Routes = [
  
  {path:'',redirectTo:'search',pathMatch:'full'} ,
  {path:'portal',component:PortalComponent},
  {path:'search',component:SearchPageComponent},
  {path:'**',redirectTo:'search',pathMatch:'full'}
]

export function api_server_factoy(platform:Object,domain:string){
  if(environment.production){
    if(isPlatformServer(platform)){
      return "http://apiserver:4200/api/";
    }
    return `https://${domain}/api/`;
  }
  return "http://localhost:4200/api/";
}

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchSuggesstionComponent,
    SearchSuggesstionItemComponent,
    SearchSwitchComponent,
    MouseWheelDirective,
    SearchPageComponent,
    PortalComponent, 
    NavComponent, 
    FeedComponent, AutoResizeDirective, ListComponent, NlistItemComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId:"youkai.net"}),
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    //{ provide: RouteReuseStrategy, useClass: SimpleReuseStrategy},
    { provide: 'ASKED_KEY',useValue:"1526210461"},
    { provide: 'ASKED_API',useValue:"https://asked.touhou.ren"},
    { provide: 'ASKED_SECRET',useValue:"9b9466cd-75bb-4b72-b128-72b06da97ea3maptouhou.link"},
    { provide: 'SITE_DOMAIN',useValue:"123.touhou.ren"},
    { provide: 'API_SERVER',useFactory:api_server_factoy,
  deps:[PLATFORM_ID,'SITE_DOMAIN']},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
