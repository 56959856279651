import {SearchEngine} from './search-engine'
import { SearchResult } from './search-result';

export class MoegirlSearchEngine implements SearchEngine {
    logoUrl="https://zh.moegirl.org/favicon.ico";
    logoSmallUrl="";
    token="moegirl";
    type="jsonp";
    jsonpCallback="callback";
    search_placehoder="使用萌百搜索";
    constructUrl(query:string):string{
        //return `https://thwiki.cc/api.php?action=opensearch&format=json&formatversion=2&search=${query}&namespace=0&limit=6&suggest=true`;
        return `https://m.moegirl.org/api.php?action=query&format=json&prop=pageimages&titles=&generator=prefixsearch&piprop=thumbnail&gpssearch=${query}`;
    }

    process(data:any):Array<SearchResult>{
        if(data.query==undefined||data.query.pages==undefined){
            return new Array();
        }
        return Object.values(data.query.pages).map(
            function (v:any) {
                return new SearchResult(v.title,'萌娘百科',undefined,v.thumbnail!=undefined?v.thumbnail.source:undefined);
            });
    }

    queryUrl(q:string):string{
        return `https://zh.moegirl.org/index.php?search=${q}`;
    }
}