import {SearchEngine} from './search-engine'
import { SearchResult } from './search-result';

export class GithubSearchEngine implements SearchEngine {
    logoUrl="/logo/github.svg";
    logoSmallUrl="";
    token="github";
    type="get.json";
    jsonpCallback=undefined;
    search_placehoder="Search on Github";
    constructUrl(query:string):string{
        return `https://api.github.com/search/repositories?q=${query}&per_page=8`;
    }

    process(data:any):Array<SearchResult>{
        return data.items.map(
            function (v) {
                return new SearchResult(v.full_name,v.description,v.html_url,v.owner.avatar_url);
            });
    }
    queryUrl(q:string):string{
        return `https://github.com/search?utf8=%E2%9C%93&q=${q}`;
    }
}