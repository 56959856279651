export class SearchResult {

    actionable:boolean=false;

    constructor(public titleRaw:string,public description:string='',public url:string=undefined,public iconUrl:string=undefined){
    }

    get title():string{
        return this.titleRaw.replace(/<[^>]+>/g,"");
    }

    tip():string{
        if(this.url!=undefined){
            return "<span class='bordered'>Alt</span> + <span class='bordered'>Enter</span> 跳转";
        }
        else{
            return undefined;
        }
    }
}

export class ActionSearchResult extends SearchResult{
    public parameter:any;

    constructor(titleRaw:string,description:string=undefined,iconUrl:string=undefined,public action:(...a:any[])=>void,public para:any){
        super(titleRaw,description,undefined,iconUrl);
        this.actionable=true;
    }

    tip(){
        return "<span class='bordered'>Enter</span> 执行";
    }
}

export class HistorySearchResult extends SearchResult{
    constructor(title:string){
        super(title,'历史记录',undefined,undefined);
    }

    tip():string{
        return "<span class='bordered'>Del</span> 删除";
    }
}

export class ChipResult extends SearchResult{
    constructor(title:string){
        super(title,undefined,undefined,undefined);
    }
}