import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ykn-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit {

  constructor() { }

  list:Array<any>=[{url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWik2i"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWii3"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki4"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki5"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki6"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki7"},
  {url:"c",icon:"https://thwiki.cc/favicon.ico",name:"THBWiki8"}];

  ngOnInit() {
  }

}
