import {SearchEngine} from './search-engine'
import { SearchResult } from './search-result';

export class BaiduSearchEngine implements SearchEngine {
    logoUrl="/logo/baidu.svg";
    logoSmallUrl="";
    token="baidu";
    type="jsonp";
    jsonpCallback="cb";
    search_placehoder="百度一下,你就知道";
    constructUrl(query:string):string{
        return `https://sp0.baidu.com/5a1Fazu8AA54nxGko9WTAnF6hhy/su?sid=&wd=${query}`;
    }

    process(data:any):Array<SearchResult>{
        return data.s.map(
            function (v) {
                return new SearchResult(v,'');
            });
    }

    queryUrl(q:string):string{
        return `https://www.baidu.com/s?wd=${q}`;
    }
}