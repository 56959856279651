import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Inject, PLATFORM_ID, HostListener, Input, Output } from '@angular/core';
import { Observable, fromEvent, Subject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { SearchResult, ActionSearchResult, HistorySearchResult, ChipResult } from '../core/search-result';
import { SearchService } from '../core/search.service';
import { AppStateService } from '../core/app-state.service';
import { trigger, state, style, transition, animate, animateChild, query, group } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

export const MAX_SUGESSTION = 6;
@Component({
  selector: 'ykn-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit {
  inputValue: string = "";
  private lastEdit: string;//真的很搞siao
  private isSearchboxFocused: boolean;
  @ViewChild("input")
  private inputbox: ElementRef;

  @Output() onFocusChange: Subject<boolean>;

  get isWorking(): boolean {
    return this.search.isWorking;
  }
  get width(){
    return document.body.clientWidth || document.documentElement.clientWidth;
  }

  /**
   * 搜索建议列表
   */
  private _suggesstionList: Array<SearchResult>;
  public get suggesstionList(): Array<SearchResult> {
    return this._suggesstionList;
  }
  public set suggesstionList(v: Array<SearchResult>) {
    if (v == null || v.length == 0) {
      this.currentSelectedIndex = -1; ///key
      this._suggesstionList = null;
    }
    else {
      if (v[0] instanceof ActionSearchResult) {
        this.currentSelectedIndex = 0; //key
      }
      else {
        this.currentSelectedIndex = -1;
      }
      this._suggesstionList = v;
    }
  }

  onClickSwitch() {
    this.search.queryAction(':');
    this.inputbox.nativeElement.focus();
  }

  /**
   * 当前选中的选项
   */
  currentSelectedIndex: number = -1;

  constructor(private search: SearchService, @Inject(PLATFORM_ID) private platform: Object) {
    this.onFocusChange = new Subject();
  }

  get isFolded(): boolean {
    return AppStateService.Maximized;
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    this.search.searchCallbackStream.subscribe(
      (res: Array<SearchResult>) => {
        this.suggesstionList = res;
      }
    )
    //this.test.nativeElement.style.height=`${this.height}px`;
    if (isPlatformBrowser(this.platform)) {
      //this.test2.nativeElement.style.height=`${this.height}px`;
    }

    //this.suggesstionList=this.s;
  }

  @HostListener("document:keydown", ['$event'])
  onspace(e: KeyboardEvent) {
    if (!this.isSearchboxFocused) {
      this.inputbox.nativeElement.focus();
      e.preventDefault();
    }
  }

  onInputUpdated() {
    if (this.inputValue.trim().length != 0 && this.lastEdit != this.inputValue) {
      this.search.searchInputStream.next(this.inputValue);//只管发送
      this.lastEdit = this.inputValue;
    }
    else if (this.inputValue.trim().length == 0) {
      //this.suggesstionList=this.s;
      this.suggesstionList = new Array();
      this.currentSelectedIndex = -1;
      this.lastEdit = "";
    }
  }

  onEnter() {
    var rs = this.currentSelected;
    if (rs == null || !(rs instanceof ActionSearchResult)) {
      this.search.logSearchHistory(this.inputValue);
      window.open(this.search.currentSearchEngine.queryUrl(this.inputValue));
    }
    else {
      (<ActionSearchResult>rs).action((<ActionSearchResult>rs).para);
      this.suggesstionList = null;
      this.inputValue = "";
      this.lastEdit = "";
      return;
    }
  }

  onAltEnter(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.currentSelected.url != undefined) {
      window.open(this.currentSelected.url);
    }
  }

  onArrowUp(e: KeyboardEvent) {
    if (this.suggesstionList == null) {
      return;
    }
    if (this.currentSelectedIndex <= 0) {
      this.currentSelectedIndex = MAX_SUGESSTION - 1;
    }
    else {
      this.currentSelectedIndex--;
    }
    //setting the input box
    this.updateSearchContext(this.currentSelected.title);
    e.preventDefault();
    e.stopPropagation();
  }

  onArrowDown(e: KeyboardEvent) {
    if (this.suggesstionList == null) {
      return;
    }
    if (this.currentSelectedIndex + 1 == MAX_SUGESSTION) {
      this.currentSelectedIndex = 0;
    }
    else {
      this.currentSelectedIndex++;
    }
    e.preventDefault();
    e.stopPropagation();
    this.updateSearchContext(this.currentSelected.title);
  }

  onTab(e: KeyboardEvent) {
    this.suggesstionList = null;
    e.preventDefault();
    if (this.search.switchSuggesstedSearchEngine()) {
      var c: string = this.inputValue;
      if (c.indexOf(' ') == -1) {
        //this.onInputUpdated("");
        this.updateSearchContext("");
      }
      else {
        var g = c.substring(c.indexOf(' ') + 1);
        //this.onInputUpdated(g);
        this.updateSearchContext(g);
      }
      e.stopPropagation();
      return;
    }
    if (e.shiftKey) {
      this.search.switchPrevSearchEngine();
    }
    else {
      this.search.switchNextSearchEngine();
    }
    //this.onInputUpdated();
  }

  /**
   * 改变搜索框内容,但不触发更改事件
   * @param q 更改值
   */
  private updateSearchContext(q: string) {
    this.lastEdit = q;
    this.inputValue = q;
  }

  onFocus() {
    this.isSearchboxFocused = true;
    this.onFocusChange.next(true);
  }

  onBlur() {
    this.isSearchboxFocused = false;
    this.onFocusChange.next(false);
  }

  onClickEntry() {
    AppStateService.Maximized = !AppStateService.Maximized;
  }

  buttonText(): string {
    return AppStateService.Maximized ? "RETURN" : "START";
  }
  onMouseWheelDown(e: any) {
    if (!AppStateService.Maximized) {
      this.onClickEntry();
      e.preventDefault();
    }
  }


  onDelete() {
    var rs = this.currentSelected;
    if (rs == null || !(rs instanceof HistorySearchResult)) {//有选项,且选择了一项
      return;
    }
    this.search.deleteSearchHistory(rs.title);
    this.suggesstionList.splice(this.currentSelectedIndex, 1);//删除
    this.suggesstionList = this.suggesstionList;//OnPush强制刷新
  }

  onItemHovered(s: number) {
    this.currentSelectedIndex = s;
  }

  /**
   * 这个方法与onEnter有相同的逻辑,但难以同构
   * @param s 
   */
  onItemClicked(s: number) {
    var rs = this.suggesstionList[s];
    if (rs instanceof ActionSearchResult) {
      (<ActionSearchResult>rs).action((<ActionSearchResult>rs).para);
      this.suggesstionList = null;
      this.inputValue = "";
      this.lastEdit = "";
    }
    else if (rs.url != undefined) {
      this.search.logSearchHistory(rs.title);
      window.open(rs.url);
    }
    else {
      this.search.logSearchHistory(rs.title);
      window.open(this.search.currentSearchEngine.queryUrl(rs.title));
    }
    setTimeout(() => {
      console.log('fff');
      this.inputbox.nativeElement.focus();
    },0);
  }

  onItemAction(e: number) {
    console.log('fuck');
    var rs = this.suggesstionList[e];
    if (rs instanceof HistorySearchResult) {
      this.search.deleteSearchHistory(rs.title);
      this.suggesstionList.splice(this.currentSelectedIndex, 1);//删除
      this.suggesstionList = this.suggesstionList;//OnPush强制刷新
    }
  }
  /*
    helpInfo() {
      if (this.redirectText == undefined) {
        return "按 <span class='bordered'>Tab</span>可切换搜索引擎";
      }
      else {
        return `按 <span class='bordered'>Tab</span>可切换到${this.redirectText}`;
      }
    }
  */
  get showSuggesstion() {
    return this.suggesstionList != null && this.isSearchboxFocused /*&& this.inputValue.trim() != ""*/;/* focused */
  }

  private get currentSelected(): SearchResult {
    if (this.suggesstionList == null || this.currentSelectedIndex < 0) {
      return null;
    }
    return this.suggesstionList[this.currentSelectedIndex];
  }
}
