import { Component, OnInit, Inject, Input,EventEmitter, Output, ChangeDetectionStrategy, HostListener, HostBinding } from '@angular/core';
import { SearchResult, ChipResult } from '../../core/search-result';

@Component({
  selector: 'ykn-search-suggesstion',
  templateUrl: './suggesstion.component.html',
  styleUrls: ['./suggesstion.component.less'],
  //changeDetection:ChangeDetectionStrategy.OnPush //展示型组件
})
export class SearchSuggesstionComponent implements OnInit {

  @Input() isShow:boolean;
  @Input() list:Array<SearchResult>;
  @Input() currentSelected:number;

  @Output() itemHovered:EventEmitter<number>=new EventEmitter();
  @Output() itemClicked:EventEmitter<number>=new EventEmitter();
  @Output() itemActioned:EventEmitter<number>=new EventEmitter();

  constructor() {  }

  ngOnInit() {}

  onClick(index:number){
    this.itemClicked.next(index);
  }

  onMouseEnter(index:number){
    this.itemHovered.next(index);
  }

  /*
  尝试由此组件handle鼠标事件
   */
  @HostListener("custom",['$event'])
  onArrowUp(e:KeyboardEvent){
    console.log('k');
  }
  @HostListener("document:keydown.ArrowDown",['$event'])
  onArrowDown(e:KeyboardEvent){
    console.log('t');
  }

  @HostBinding('class')
  get _class(){
    return `${this.isShow?'show':''} ${this.isChips?'chips':''}`;
  }

  get isChips():boolean{
    if(this.list==null){
      return false;
    }
    return this.list[0] instanceof ChipResult;
  }

  onAction(index:number){
    this.itemActioned.next(index);
  }
}
