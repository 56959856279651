import { SearchEngine } from './search-engine'
import { SearchResult } from './search-result';

export class BingSearchEngine implements SearchEngine {
    logoUrl = "/logo/bing.svg";
    logoSmallUrl = "";
    token = "bing";
    type = "post.text";
    jsonpCallback = undefined;
    search_placehoder="Search on Bing";
    constructUrl(query: string): string {
        return `https://cors-anywhere.herokuapp.com/https://cn.bing.com/AS/Suggestions?qry=${query}&cvid=0`;
    }

    process(data: any): Array<SearchResult> {
        var result = new Array();
        var re = /<div class="sa_tm">(.*?)<\/div>/g;
        var tempR;
        while (tempR = re.exec(data)) {
            var c = new SearchResult(tempR[1]);
            result.push(c);
        }
        return result;
    }

    queryUrl(q: string): string {
        return `https://cn.bing.com/search?q=${q}`;
    }
}