import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchEngine } from './search-engine';
import { BaiduSearchEngine } from './baidu-search-engine';
import { GoogleSearchEngine } from './google-search-engine';
import { GithubSearchEngine } from './github-search-engine';
import { Subject, Subscription, Observable } from 'rxjs';
import { map, startWith, skip, tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SearchResult, ActionSearchResult, HistorySearchResult } from './search-result';
import { AppStateService } from './app-state.service';
import { BingSearchEngine } from './bing-search-engine';
import { THBSearchEngine } from './thb-search-engine';
import { isPlatformBrowser } from '@angular/common';
import { MoegirlSearchEngine } from './moegirl-search-engine';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchEngines: Array<SearchEngine> = new Array<SearchEngine>();

  //currentSearchEngine: SearchEngine;
  private _currentSearchEngineIndex: number=-1;

  get currentSearchEngineIndex(){
    return this._currentSearchEngineIndex;
  }
  set currentSearchEngineIndex(v){
    if(v<0||v>=this.searchEngines.length){
      this._currentSearchEngineIndex=0;
    }
    else{
      this._currentSearchEngineIndex=v;
    }
    this.app.set("lastEngine",this.currentSearchEngine.token);
  }

  get currentSearchEngine() {
    return this.searchEngines[this.currentSearchEngineIndex];
  }

  searchCallbackStream: Subject<Array<SearchResult>>;

  searchInputStream: Subject<string>;

  lastSubscription: Subscription;

  searchHistory: { [key: string]: Array<string> };

  isWorking: boolean;

  constructor(private http: HttpClient, private app: AppStateService, @Inject(PLATFORM_ID) private platform: Object) {
    if (isPlatformBrowser(this.platform)) {
      this.searchCallbackStream = new Subject();
      this.searchInputStream = new Subject();
      this.searchInputStream.pipe(
        distinctUntilChanged(),
        filter(x => !this.queryAction(x)),
        tap(x => this.flush()),
        debounceTime(200))
        .subscribe((x: string) => { this.query(x) });
      this.searchEngines.push(new BaiduSearchEngine());
      this.searchEngines.push(new GoogleSearchEngine());
      this.searchEngines.push(new GithubSearchEngine());
      this.searchEngines.push(new BingSearchEngine());
      this.searchEngines.push(new THBSearchEngine());
      this.searchEngines.push(new MoegirlSearchEngine());
      //this.currentSearchEngine = this.searchEngines[0];//通过用户定义
      this.searchHistory = app.getObject("searchHistory");
      if (this.searchHistory == undefined) {
        this.searchHistory = undefined;
      }
      if(app.get("lastEngine")!=undefined){
        this.switchSearchEngine(app.get("lastEngine"));
        console.log(this.currentSearchEngineIndex);
      }
      else{
        this.currentSearchEngineIndex = 0;
      }
    }
  }

  private flush() {
    if (this.lastSubscription != null) {
      this.lastSubscription.unsubscribe();
      this.lastSubscription = null;
    }
  }

  /**
   * TODO:重写增加扩展性 装饰器
   * @param text 
   */
  public queryAction(text: string): boolean {
    var fresult: Array<SearchResult> = new Array();
    if (text.startsWith(':')) {
      if (":baidu".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>百度</b>", "快速切换", "/logo/baidu.svg",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("baidu"); }));
      }
      if (":google".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>谷歌</b>", "快速切换", "/logo/google.svg",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("google"); }));

      } if (":github".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>Github</b>", "快速切换", "/logo/github.svg",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("github"); }));

      } if (":bing".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>必应</b>", "快速切换", "/logo/bing.svg",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("bing"); }));

      } if (":thwiki".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>THBWiki</b>", "快速切换", "http://thwiki.cc/favicon.ico",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("thbwiki"); }));

      } if (":moegirl".startsWith(text)) {
        fresult.push(new ActionSearchResult("切换到 <b>萌娘百科</b>", "快速切换", "https://zh.moegirl.org/favicon.ico",
          (s: () => void) => { s(); }, () => { this.switchSearchEngine("moegirl"); }));

      }
      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("av") && (/^av\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <b><i>${text}</i></b>`, "哔哩哔哩", "https://www.bilibili.com/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("https://bilibili.com/video/" + text) }));
      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("ac") && (/^ac\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <b><i>${text}</i></b>`, "AcFun", "http://acfun.cn/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("http://acfun.cn/v/" + text) }));
      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("pid") && (/^pid\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <i>作品</i> <b><i>${text}</i></b>`, "Pixiv", "https://pixiv.net/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("https://www.pixiv.net/member_illust.php?mode=medium&illust_id=" + text.substring(3)) }));
      fresult.push(new ActionSearchResult(`跳转到 <i>画师</i> <b><i>${text}</i></b>`, "Pixiv", "https://pixiv.net/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("https://www.pixiv.net/member.php?id=" + text.substring(3)) }));


      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("sm") && (/^sm\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <b><i>${text}</i></b>`, "ニコニコ动画", "http://nicovideo.jp/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("http://www.nicovideo.jp/watch/" + text) }));
      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("td") && (/^td\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <b><i>${text}</i></b>`, "ニコニ立体", "http://3d.nicovideo.jp/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("http://3d.nicovideo.jp/works/" + text) }));
      this.searchCallbackStream.next(fresult);
      return true;
    }
    if (text.startsWith("im") && (/^im\d{1,}$/).test(text)) {
      fresult.push(new ActionSearchResult(`跳转到 <b><i>${text}</i></b>`, "ニコニコ静画", "http://seiga.nicovideo.jp/favicon.ico",
        (s: () => void) => { s(); }, () => { window.open("http://seiga.nicovideo.jp/seiga/" + text) }));
      this.searchCallbackStream.next(fresult);
      return true;
    }
    return false;
  }

  private query(text: string): any {
    var httpObservable: Observable<Object>;
    switch (this.currentSearchEngine.type) {
      case "post.text":
        httpObservable = this.http.post(this.currentSearchEngine.constructUrl(text), null, { responseType: "text" });
        break;
      case "get.json":
        httpObservable = this.http.get(this.currentSearchEngine.constructUrl(text));
        break;
      case "jsonp":
        httpObservable = this.http.jsonp(this.currentSearchEngine.constructUrl(text), this.currentSearchEngine.jsonpCallback)
        break;
    }
    this.isWorking = true;
    this.lastSubscription = httpObservable.pipe(
      map(res => this.currentSearchEngine.process(res)),
      map(array => this.queryHistory(text).concat(array))
    ).subscribe(
      (res: Array<SearchResult>) => {
        this.searchCallbackStream.next(res);
        this.isWorking = false;
      }
    )
    /* 后处理 */
  }

  /**
   * TODO:
   * @param text 
   */
  queryHistory(text: string): Array<SearchResult> {
    if (this.searchHistory[this.currentSearchEngine.token] == undefined) {
      return new Array<SearchResult>();
    }
    return this.searchHistory[this.currentSearchEngine.token].filter(x => x.startsWith(text)).map(
      function (v) {
        return new HistorySearchResult(v);
      }
    );
  }

  switchSearchEngine(token: string): any {
    this.currentSearchEngineIndex = this.searchEngines.findIndex(x => x.token == token);
  }

  switchNextSearchEngine() {
    this.currentSearchEngineIndex++;//保证原子性操作的情况下
    if (this.currentSearchEngineIndex == this.searchEngines.length)
      this.currentSearchEngineIndex = 0;
  }

  switchPrevSearchEngine() {
    this.currentSearchEngineIndex--;
    if (this.currentSearchEngineIndex < 0)
      this.currentSearchEngineIndex = this.searchEngines.length - 1;
  }

  //TODOTODOTODO
  switchSuggesstedSearchEngine(): boolean {
    return false;
  }

  //TODO
  logSearchHistory(ctx: string) {
    if (ctx.trim().length == 0) {
      return;
    }
    if (this.searchHistory[this.currentSearchEngine.token] == undefined) {
      this.searchHistory[this.currentSearchEngine.token] = new Array<string>();
    }
    var c = this.searchHistory[this.currentSearchEngine.token];
    c.push(ctx);

    /*MAX LENGTH*/
    if (c.length > 10) {
      c.shift();
    }
    this.app.setObject("searchHistory", this.searchHistory);
  }

  //TODO
  deleteSearchHistory(ctx: string) {
    if (this.searchHistory[this.currentSearchEngine.token] == undefined) {
      this.searchHistory[this.currentSearchEngine.token] = new Array<string>();
      return;
    }
    this.searchHistory[this.currentSearchEngine.token].splice(
      this.searchHistory[this.currentSearchEngine.token].findIndex(x => x == ctx), 1);
    this.app.setObject("searchHistory", this.searchHistory);
  }
}
