import { Component, OnInit, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'ykn-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.less']
})
export class PortalComponent implements OnInit {

  rightStyle:any={};

  constructor() { }

  date:Array<number>;

  ngOnInit() {
    var now = new Date();
    var firstDay = new Date(now.getFullYear(),now.getMonth(),1);
    this.date=new Array();
    for(var i=1;i<31;i++){
      this.date.push(i);
    }
    window.onload=()=>{
      this.rightStyle={transform:'translateX(0)',opacity:'1'};
    };
  }
}
