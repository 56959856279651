import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, delay, bufferTime } from 'rxjs/operators';

@Component({
  selector: 'ykn-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.less']
})
export class SearchPageComponent implements OnInit {

  private isMaskShow:boolean;

  constructor() { this.bgs=''; 
  this.dbt=new Subject();
  this.dbt.pipe().subscribe(x=>{
    //this.hidelogo=false;
    //this.bgs_logo={transform:`translateX(${1*x.c}%) translateY(${4*x.d}%) rotateY(${-3*x.c}deg)  rotateX(${3*x.d}deg)`};
    
    this.bgs={transform:`translateX(${-20*x.c}px) translateY(${-20*x.d}px) scale(1.2)`};
    //this.bgs_search={transform:`translateY(${this.height/2}px)`};
  });
  
  /*this.dbt.pipe(bufferTime(200)).subscribe((g:Array<any>)=>{
    //this.hidelogo=false;
    if(g.length==0){
      return;
    }
    var x = g.pop();
    //this.bgs_logo={transform:`translateX(${2*x.c}%) translateY(${20*x.d}%) rotateY(${-10*x.c}deg)  rotateX(${10*x.d}deg)`};
  });*/
  /* 
  this.dbt2=new Subject();
  this.dbt2.pipe(delay(1500)).subscribe(x=>{
    this.hidelogo=true;
    this.dbt.next(null);
  })*/
  }

  get height(){
    return document.body.clientHeight || document.documentElement.clientHeight;
  }
  get width(){
    return document.body.clientWidth || document.documentElement.clientWidth;
  }

  onClickMask(){
    console.log(1);
      this.bgs_search={transform:`translateY(${120}px)`};
      this.isMaskShow=false;
  }

  onFocusChange(e:boolean){
    if(!e){
      //this.bgs_search={transform:`translateY(${this.height/2}px)`};
    }
    else if(this.width<=425){
      this.bgs_search={transform:`translateY(${1}em)`};
      this.isMaskShow=true;
    }
  }

  ngOnInit() {
    this.bgs_search={transform:`translateY(${120}px)`};
  }

  dbt:Subject<any>;
  dbt2:Subject<any>;

  hidelogo:boolean;

  bgs:any;bgs_logo:any;bgs_search:any;

  //@HostListener('document:mousemove',['$event'])
  onMouseMove(e:MouseEvent){
    var c=(e.clientX-document.documentElement.clientWidth/2)/document.documentElement.clientWidth;
    var d=(e.clientY-document.documentElement.clientHeight/2)/document.documentElement.clientHeight;
    this.dbt.next({c:c,d:d});
    //this.bgs={transform:`translateX(${20*c}px) translateY(${-20*d}px) rotateX(${10*d}deg) rotateY(${10*c}deg) scale(1.2)`};
    //this.bgs={transform:`translateX(${-10*c}px) translateY(${-10*d}px) scale(1.2)`};// rotateY(${-10*c}deg) rotateX(${10*d}deg) 
    //this.bgs_logo={transform:`translateX(${10*c}px) translateY(${10*d}px) rotateY(${-22*c}deg)  rotateX(${22*d}deg) scale(1.2)`};
    //this.dbt2.next(null);
    
  }
}
