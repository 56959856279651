import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SimpleReuseStrategy } from './core/route-reuse';
import { routeAnimation } from './app.animation';
import { Router, NavigationEnd } from '@angular/router';
import { canvasRGBA } from 'stackblur-canvas';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'ykn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations:[routeAnimation]
})
export class AppComponent implements OnInit {

  get height(){
    return document.body.clientHeight || document.documentElement.clientHeight;
  }
  get width(){
    return document.body.clientWidth || document.documentElement.clientWidth;
  }

  private lastWidth:number;
  private lastHeight:number;

  constructor(private router:Router,private element:ElementRef,private render:Renderer2){
    /*this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
      // 每次路由跳转改变状态
      this.routerState = !this.routerState;
      this.routerStateCode = this.routerState ? 'active' : 'inactive';
      }
    });*/
    console.log(`草根妖怪網絡 Front-End by 3Shain                                    
                          WNK0Ok0N      
                       WKxl;'..:OW      
   WNKK00KXW         W0l'':c'.:K        
   Kc''..',:okX    N0d,;d0W0,.kW        
   Wx,..;dxdc;;oKWXl';xN  Wd.cX         
    WO;.'xN WNOoldllkK    O;cK          
      Xd,'lKW   NOkX    XkokN           
        XklldkOKNW      NdcxN           
           WXk,;0NN   XX Nd':0W         
             X:.x0xX XxKN0Ko.,OW        
             K:.lKocdcoNOdXK;.;0        
             k'.cXK;..xXldWWd..lX       
            Kc..dNO,.,o:;O  O,.'k       
           Xc..;0O,,cxo.'xN K:..lN      
         W0:..,xd,,dOo'',':OK:..,0      
         WKkkkKXOkKX0OkKXOk0X0kkON                                          
    `);
    //this.render.setStyle(this.element.nativeElement,'height',this.height);
    //this.render.setStyle(this.element.nativeElement,'width',this.width);
    this.element.nativeElement.style.height=this.height+'px';
    this.element.nativeElement.style.width=this.width+'px';
    this.lastHeight=this.height;
    this.lastWidth=this.width;
    fromEvent(window,'resize').subscribe((e:Event)=>{
      if(this.width==this.lastWidth){
        if(this.width<425){
          return;
        }
      }
      this.element.nativeElement.style.height=this.height+'px';
      this.element.nativeElement.style.width=this.width+'px';
      this.lastHeight=this.height;
      this.lastWidth=this.width;
    });
  }

  list:Array<string>=["/img/2.jpg","/img/3.jpg","/img/4.jpg","/img/5.jpg"];

  first:boolean=true;
  currentImg=0;

  canvas1:HTMLCanvasElement;
  canvas2:HTMLCanvasElement;

  ngOnInit(){
    this.canvas1 =<HTMLCanvasElement>document.getElementById("bg");
    this.canvas2 =<HTMLCanvasElement>document.getElementById("bg2");
    setInterval(()=>{
      this.timer(false);}
      ,10000);
      this.timer(true);
  }

  getImg():string{
      var ret = this.list[this.currentImg];
    if(this.currentImg+1==this.list.length){
      this.currentImg=0;
    }
    else{
      this.currentImg++;
    }
    return ret;
  }

  timer(st:boolean){
    console.log(2);
    var canvas;
    if(st){
      canvas=this.canvas1;
    }
    else if(this.first){
      canvas=this.canvas2;
    }
    else{
      canvas=this.canvas1;
    }
    var g =canvas.getContext("2d");
    var img = new Image();
    img.src=this.getImg();
    img.onload=()=>{
      canvas.height=img.height;
      canvas.width=img.width;
      g.drawImage(img,0,0);
      g.globalCompositeOperation='destination-over';
      canvasRGBA(canvas,0,0,canvas.width,canvas.height,25);
      if(!st){
        this.first=!this.first;}
    }
  }
}
