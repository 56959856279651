import {SearchEngine} from './search-engine'
import { SearchResult } from './search-result';

export class GoogleSearchEngine implements SearchEngine {
    logoUrl="/logo/google.svg";
    logoSmallUrl="";
    token="google";
    type="jsonp";
    jsonpCallback="callback";
    search_placehoder="Search on Google";
    constructUrl(query:string):string{
        return `https://www.google.com/complete/search?client=psy-ab&q=${query}`;
    }

    process(data:any):Array<SearchResult>{
        return data[1].map(
            function (v) {
                return new SearchResult(v[0]);
            });
    }
    queryUrl(q:string):string{
        return `https://www.google.com/search?q=${q}`;
    }
}